import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getStats() {
      return new Promise((resolve, reject) => {
        axios
          .get('dash/stats')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    //  commented out on 20.2.
    /*
    list(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/list/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    one(ctx, { userId, carId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get/${userId}/${carId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    add(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/add/${data.userId}`, data.newCar.value)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    */
    // actions end
  },
}
