<template>
  <b-card no-body>
    <b-card-body class="d-flex align-items-center text-center">
      <div
        class="truncate"
        style="width:100%"
      >
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <b-badge
          :style="badgeColor"
        >
          {{ statisticTitle }}
        </b-badge>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BBadge } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    badgeColor: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
