<template>
  <div>
    <b-row>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="primary"
          icon="EyeIcon"
          :statistic="dashStats.partners"
          statistic-title="Partner(aktiv)"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="info"
          icon="UsersIcon"
          :statistic="dashStats.users"
          statistic-title="Benutzer"
        />
      </b-col>
      <b-col
        xl="4"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          hide-chart
          color="success"
          icon="BatteryChargingIcon"
          :statistic="dashStats.cars"
          statistic-title="Bonus beantragt"
        />
      </b-col>
    </b-row>
    <hr>
    <h4> Bonus Status </h4>

    <b-row>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status10"
          :statistic-title="getStatusTitle(10)"
          :badge-color="getStatusColor(10)"
        />
      </b-col>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status20"
          :statistic-title="getStatusTitle(20)"
          :badge-color="getStatusColor(20)"
        />
      </b-col>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status21"
          :statistic-title="getStatusTitle(21)"
          :badge-color="getStatusColor(21)"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status30"
          :statistic-title="getStatusTitle(30)"
          :badge-color="getStatusColor(30)"
        />
      </b-col>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status60"
          :statistic-title="getStatusTitle(60)"
          :badge-color="getStatusColor(60)"
        />
      </b-col>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status80"
          :statistic-title="getStatusTitle(80)"
          :badge-color="getStatusColor(80)"
        />
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status40"
          :statistic-title="getStatusTitle(40)"
          :badge-color="getStatusColor(40)"
        />
      </b-col>
      <b-col
        xl="2"
        lg="3"
        md="4"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status50"
          :statistic-title="getStatusTitle(50)"
          :badge-color="getStatusColor(50)"
        />
      </b-col>
      <b-col
        lg="2"
        sm="6"
      >
        <statistic-card-horizontal
          :statistic="dashStats.status70"
          :statistic-title="getStatusTitle(70)"
          :badge-color="getStatusColor(70)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
} from 'bootstrap-vue'

import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontalNoIcon.vue'

import {
  onUnmounted,
  ref,
} from '@vue/composition-api'

import store from '@/store'
import homeStoreModule from './homeStoreModule'
import { bonusStatus } from '../../enums'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    StatisticCardVertical,
    StatisticCardHorizontal,
  },
  setup() {
    const STORE_MODULE = 'home-store-module'
    //  Register StoreModule
    if (!store.hasModule(STORE_MODULE)) store.registerModule(STORE_MODULE, homeStoreModule)

    //  Unregister StoreModule
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE)) store.unregisterModule(STORE_MODULE)
    })

    const getStatusTitle = statusId => bonusStatus[parseInt(statusId, 10)]

    const getStatusColor = statusId => {
      let backgroundColor = 'rgba(40, 118, 251, 0.12)'
      let color = '#2876fb'

      if (parseInt(statusId, 10) === 10) {
        backgroundColor = 'rgba(40, 118, 251, 0.12)'
        color = '#2876fb'
      }

      if (parseInt(statusId, 10) === 20) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#82868b'
      }

      if (parseInt(statusId, 10) === 21) {
        backgroundColor = 'rgba(130, 134, 139, 0.12)'
        color = '#df4d12'
      }

      if (parseInt(statusId, 10) === 30) {
        backgroundColor = '#2876fb'
        color = '#ffffff'
      }

      if (parseInt(statusId, 10) === 40) {
        backgroundColor = 'rgba(223, 77, 18, 0.12)'
        color = '#df4d12'
      }

      if (parseInt(statusId, 10) === 50) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      }

      if (parseInt(statusId, 10) === 60) {
        backgroundColor = '#7c9d2b'
        color = '#ffffff'
      }

      if (parseInt(statusId, 10) === 70) {
        backgroundColor = '#df4d12'
        color = '#ffffff'
      }

      if (parseInt(statusId, 10) === 80) {
        backgroundColor = '#000000'
        color = '#ffffff'
      }

      return `background-color:${backgroundColor}; color:${color};`
    }

    const dashStats = ref({})
    store
      .dispatch(`${STORE_MODULE}/getStats`)
      .then(response => {
        console.log(response)
        dashStats.value = response.data
      })
      .catch(error => {
        console.log(error)
      })

    return {
      dashStats,
      getStatusTitle,
      getStatusColor,
    }
  },
}
//
</script>
